<template>
  <v-card class="mt-n10 moholand-shadow">
    <v-card-title>
      ایجاد ژانر جدید
      <v-spacer></v-spacer>

      <v-btn color="primary" :to="destinationRoute">
        <v-icon>mdi-undo-variant</v-icon>
        بازگشت
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" @submit.prevent="submit">
        <v-form-base
          :col="{ cols: 12, md: 6 }"
          :model="model"
          :schema="schema"
        />
        <v-row align="center" justify="end" class="mt-8">
          <v-btn @click="clear" depressed>
            پاک کردن فرم
          </v-btn>

          <v-btn
            class="mx-4"
            dark
            color="success"
            :loading="loading"
            type="submit"
          >
            ایجاد ژانر جدید
          </v-btn>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import VFormBase from "vuetify-form-base";
import { formPostPrepare } from "@/constants/PanelFormHandler";
export default {
  components: {
    VFormBase,
  },
  data() {
    return {
      rules: Constants.rules,
      destinationRoute:
        this.$route.fullPath.slice(
          0,
          this.$route.fullPath.indexOf("create") - 1
        ) + (this.$route.query?.page ? "?page=" + this.$route.query?.page : ""),
      loading: false,
      model: {
        name: "",
        slug: "",
      },
      schema: {
        name: {
          type: "CustomInput",
          label: "نام ژانر",
          rules: [Constants.rules.required],
          required: true,
        },
        slug: {
          type: "CustomInput",
          label: "اسلاگ",
          rules: [Constants.rules.required],
          required: true,
        },
      },
    };
  },
  created() {
    // this.getData();
    this.getGenres();
  },

  methods: {
    getData() {
      MyAxios.get("/lesson/index")
        .then((response) => {
          this.lessons = response.data.data;
        })
        .catch((error) => {
          console.log("laws", error.response);
        });
    },
    getGenres() {
      MyAxios.get("/genres/index", {
        params: {
          noPaginate: true,
        },
      })
        .then((response) => {
          this.schema.parent_id = {
            ...this.schema.parent_id,
            loading: false,
            items: response.data.map((val) => ({
              id: val.id,
              value: val.name,
            })),
          };
        })
        .catch((error) => {
          console.log("laws", error.response);
        });
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        let data = formPostPrepare(this.model);

        MyAxios.post("genres/create", data)
          .then((response) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: "ژانر با موفقیت ایجاد شد.",
              type: "success",
            });
            console.log(response);
            this.$router.replace(this.destinationRoute);
          })
          .catch((error) => {
            this.loading = false;

            if (parseInt(error.response.status) === 500) {
              this.$root.$emit("toast", {
                text:
                  "اسلاگ وارد شده تکراری است یا اشکالی در ثبت اطلاعات به وجود آمده است",
              });
            } else {
              this.$root.$emit("toast", {
                text: "مشکلی پیش آمده است. لطفا دوباره تلاش کنید.",
              });
            }
            console.log(error);
          });
      } else {
        this.$root.$emit("toast", {
          text: "لطفا اطلاعات را به درستی وارد نمایید.",
        });
      }
    },
    clear() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style></style>
